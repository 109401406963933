<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeLocationDialog('LocationEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Location"
                    :rules="rules"
                    ref="LocationEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="库位编号" prop="name">
                            <el-input v-model="Location.name"></el-input>
                        </el-form-item>
                        <el-form-item label="所属仓库" prop="storeroom_id">
                            <el-cascader
                                v-model="Location.storeroom_id"
                                :options="storeroom_in_enterprise"
                                :props="{ expandTrigger: 'hover' }"
                                @change="(e) => storeroomChange(e)"
                                clearable
                                style="width: 100%"
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="所属库区" prop="region">
                            <el-select
                                v-model="Location.region"
                                placeholder="选择库区"
                                class="regionc"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="r in regions"
                                    :key="r"
                                    :label="r"
                                    :value="r"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="库位总面积" prop="area">
                            <el-input v-model="Location.area" clearable><i slot="suffix">㎡</i></el-input>
                        </el-form-item>
                        <el-form-item label="库位总容量" prop="max_weight">
                            <el-input v-model="Location.max_weight" clearable><i slot="suffix">吨</i></el-input>
                        </el-form-item>
                        <el-form-item label="最大托盘数" prop="max_tray_num">
                            <el-input v-model.number="Location.max_tray_num" clearable><i slot="suffix">个</i></el-input>
                        </el-form-item>
                        <el-form-item label="库位类型" prop="type">
                            <el-radio-group v-model="Location.type" @change='e => clickGroup(e)'>
                                <el-radio
                                    :label=0
                                    key="0"
                                    >仓储</el-radio
                                >
                                <el-radio
                                    :label=1
                                    key="1"
                                    >仓租</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <div v-if="Location.type === 1">
                            <el-form-item label="客户" prop="client_id">
                                <el-select
                                    v-model="Location.client_id"
                                    filterable
                                    placeholder="请选择客户"
                                    class="clientc"
                                >
                                    <el-option
                                        v-for="item in AllClient"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>库位编号</span>
                                </template>
                                <span>{{Location.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属仓库</span>
                                </template>
                                <span>{{Location.storeroom_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属库区</span>
                                </template>
                                <span>{{Location.region}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>库位总面积</span>
                                </template>
                                <span>{{Location.area ? Location.area + '㎡' : ''}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>库位总容量</span>
                                </template>
                                <span>{{Location.max_weight ? Location.max_weight + '吨' : ''}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                <template slot="label">
                                    <span>已使用容量</span>
                                </template>
                                <span>{{(Location.used_weight ? Location.used_weight/1000 : 0) | diyNum}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00DB00'}">
                                <template slot="label">
                                    <span>剩余容量</span>
                                </template>
                                <span>{{(Location.max_weight ? (Location.max_weight - Location.used_weight/1000) : 0) | diyNum}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>最大托盘空间</span>
                                </template>
                                <span>{{Location.max_tray_num ? Location.max_tray_num + '个' : ''}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label" :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <span>已用托盘空间</span>
                                </template>
                                <span>{{Location.used_tray_num ? Location.used_tray_num + '个' : '0个'}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00DB00'}">
                                <template slot="label">
                                    <span>剩余托盘空间</span>
                                </template>
                                <span>{{Location.max_tray_num ? (Location.max_tray_num - Location.used_tray_num) + '个' : '0个'}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>库位类型</span>
                                </template>
                                <el-tag v-if="Location.type === 0">仓储</el-tag>
                                <el-tag v-else-if="Location.type === 1" type="warning">仓租</el-tag>
                                <span v-else></span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>租用客户</span>
                                </template>
                                <span>{{Location.client_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="LocationDel('LocationEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeLocationDialog('LocationEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('LocationEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('LocationEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeLocationDialog('LocationEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('LocationEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeLocationDialog('LocationEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { location_add_request, location_del_request, location_edit_request } from '@/network/WareHouse/location.js'
import { enterprise_storeroom_request } from '@/network/enterprise.js'
import { storeroom_list_request } from '@/network/list.js'
import { fomatFloat } from '@/assets/js/common.js'

export default {
    name: 'LocationEditComponent',
    data() {
        return {
            login_loading: false,
            Location: {
                name: '',
                region: '',
                area: '',
                max_weight: '',
                max_tray_num: '',
                storeroom_id: '',
                storeroom_name: '',
                type: '',
                client_id: '',
                client_name: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入系统名称',
                        trigger: 'blur',
                    },
                ],
                region: [
                    {
                        required: true,
                        message: '请选择库区',
                        trigger: 'blur',
                    },
                ],
                area: [
                    {
                        required: true,
                        message: '请填写面积',
                        trigger: 'blur',
                    },
                ],
                max_weight: [
                    {
                        required: true,
                        message: '请填写容量',
                        trigger: 'blur',
                    },
                ],
                storeroom_id: [
                    {
                        required: true,
                        message: '请选择所属仓库',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '请选择库位类型',
                        trigger: 'blur',
                    },
                ],
            },
            storeroom_in_enterprise: [],
            regions: '',
            AllClient: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '库位详情'
            } else if (this.show_type === 1) {
                return '添加库位'
            } else {
                return '修改库位'
            }
        },
    },
    methods: {
        closeLocationDialog(formName) {
            this.Location = {
                name: '',
                region: '',
                area: '',
                max_weight: '',
                max_tray_num: '',
                storeroom_id: '',
                storeroom_name: '',
                type: '',
                client_id: '',
                client_name: '',
            }
            this.$emit('exitLocationDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.LocationInsert(formName)
            })
        },
        LocationInsert(formName) {
            this.login_loading = true
            let d = this.Location
            d.storeroom_id = d.storeroom_id[d.storeroom_id.length - 1]
            location_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitLocationDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.LocationEdit(formName)
            })
        },
        LocationEdit(formName) {
            this.login_loading = true
            this.Location.id = this.id
            let d = this.Location
            d.storeroom_id = d.storeroom_id[d.storeroom_id.length - 1]
            location_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitLocationDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.Location.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        LocationDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    location_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeLocationDialog(formName))
                })
                .catch((err) => {})
        },
        enterpriseChange(e) {
            this.$emit('fenterpriseChange', e)
        },
        clickGroup(e) {
            if( e === 0) {
                this.Location.client_id = ''
            }
        },
        get_enterprise_storeroom() {
            this.login_loading = true
            enterprise_storeroom_request()
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.storeroom_in_enterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        storeroomChange(e) {
            this.Location.region = ''
            storeroom_list_request({storeroom_id:e[e.length - 1]})
                .then((s) => {
                    if (s.status === 0) {
                        this.regions = s.result[0].region
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters: {
        diyNum(n) {
            return n===0 ? '0吨' : fomatFloat(n) + '吨'
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        location_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllEnterprise: {
            type: Array,
            default() {
                return []
            },
        },
        fAllClient: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {
        this.get_enterprise_storeroom()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        location_details_data(newVal) {
            this.Location = newVal
        },
        fAllClient(newVal) {
            this.AllClient = newVal
        },
    },
}
</script>

<style lang='less'>
.enterprisec {
    width: 100%;
}
.clientc {
    width: 100%;
}
.regionc {
    width: 100%;
}
</style>
<template>
    <div>
         <el-dialog
            :title="dialogFormTitle"
            :visible.sync="PlanVisible"
            :append-to-body="true"
            :width="dialog_width"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeLocationPlanDialog()"
        >
            <el-row class="rowc" v-if="(regions === 'AB')">
                <el-col :span="9">
                    <el-row v-for="itemA in dataA" :key="itemA.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemA.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemA)" :color="color_weight(itemA)" :format="(percentage) => diy_weight(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemA)" :color="color_tray_num(itemA)" :format="(percentage) => diy_tray_num(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="9">
                    <el-row v-for="itemB in dataB" :key="itemB.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemB.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemB)" :color="color_weight(itemB)" :format="(percentage) => diy_weight(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemB)" :color="color_tray_num(itemB)" :format="(percentage) => diy_tray_num(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="rowc" v-if="(regions === 'CD')">
                <el-col :span="9">
                    <el-row v-for="itemC in dataC" :key="itemC.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemC.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemC)" :color="color_weight(itemC)" :format="(percentage) => diy_weight(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemC)" :color="color_tray_num(itemC)" :format="(percentage) => diy_tray_num(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="9">
                    <el-row v-for="itemD in dataD" :key="itemD.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemD.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemD)" :color="color_weight(itemD)" :format="(percentage) => diy_weight(percentage,itemD)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemD)" :color="color_tray_num(itemD)" :format="(percentage) => diy_tray_num(percentage,itemD)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="rowc" v-else-if="regions === 'ABCD'">
                <el-col :span="5">
                    <el-row v-for="itemA in dataA" :key="itemA.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemA.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemA)" :color="color_weight(itemA)" :format="(percentage) => diy_weight(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemA)" :color="color_tray_num(itemA)" :format="(percentage) => diy_tray_num(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="2">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="5">
                    <el-row v-for="itemB in dataB" :key="itemB.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemB.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemB)" :color="color_weight(itemB)" :format="(percentage) => diy_weight(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemB)" :color="color_tray_num(itemB)" :format="(percentage) => diy_tray_num(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="5">
                    <el-row v-for="itemC in dataC" :key="itemC.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemC.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemC)" :color="color_weight(itemC)" :format="(percentage) => diy_weight(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemC)" :color="color_tray_num(itemC)" :format="(percentage) => diy_tray_num(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="2">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="5">
                    <el-row v-for="itemD in dataD" :key="itemD.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemD.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemD)" :color="color_weight(itemD)" :format="(percentage) => diy_weight(percentage,itemD)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemD)" :color="color_tray_num(itemD)" :format="(percentage) => diy_tray_num(percentage,itemD)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="rowc" v-else-if="regions === 'ABCE'">
                <el-col :span="4">
                    <el-row v-for="itemA in dataA" :key="itemA.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemA.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemA)" :color="color_weight(itemA)" :format="(percentage) => diy_weight(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemA)" :color="color_tray_num(itemA)" :format="(percentage) => diy_tray_num(percentage,itemA)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="2">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-row v-for="itemB in dataB" :key="itemB.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemB.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemB)" :color="color_weight(itemB)" :format="(percentage) => diy_weight(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemB)" :color="color_tray_num(itemB)" :format="(percentage) => diy_tray_num(percentage,itemB)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <el-row v-for="itemC in dataC" :key="itemC.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemC.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemC)" :color="color_weight(itemC)" :format="(percentage) => diy_weight(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemC)" :color="color_tray_num(itemC)" :format="(percentage) => diy_tray_num(percentage,itemC)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="2">
                    <div class="plandivcb">
                        <p>仓</p>
                        <p>库</p>
                        <p>过</p>
                        <p>道</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <el-row v-for="itemE in dataE" :key="itemE.name">
                        <el-col :span="4">
                            <div class="plandivca">{{itemE.name}}</div>
                        </el-col>
                        <el-col :span="20">
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_weight(itemE)" :color="color_weight(itemE)" :format="(percentage) => diy_weight(percentage,itemE)"></el-progress>
                                </div>
                            </el-row>
                            <el-row>
                                <div class="plandivc">
                                    <el-progress :text-inside="true" :stroke-width="19" :percentage="compute_tray_num(itemE)" :color="color_tray_num(itemE)" :format="(percentage) => diy_tray_num(percentage,itemE)"></el-progress>
                                </div>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { storeroom_list_request } from '@/network/list.js'

export default {
    name:'LocationPlanComponent',
    data(){
        return {
            dialogFormTitle: '仓库使用情况',
            dataA: [],
            dataB: [],
            dataC: [],
            dataD: [],
            dataE: [],
            regions: '',
            dialog_width: '80%'
        }
    },
    computed:{},
    methods:{
        closeLocationPlanDialog() {
            this.$emit('exitLocationPlanDialog')
        },
        compute_weight(item) {
            if(item.client_name === null) {
                return (item.used_weight /1000 * 100) / item.max_weight
            } else {
                return 100
            } 
        },
         diy_weight(percentage,item) {
            if(item.client_name === null) {
                return Math.floor(percentage) + '%（' + item.used_weight + '/' + (item.max_weight * 1000) + '）'
            } else {
                return '100%（' + item.client_name + '）'
            } 
        },
        compute_tray_num(item) {
            if(item.client_name === null) {
                return (item.used_tray_num * 100) / item.max_tray_num
            } else {
                return 100
            } 
        },
        diy_tray_num(percentage,item) {
            if(item.client_name === null) {
                return Math.floor(percentage) + '%（' + item.used_tray_num + '/' + item.max_tray_num + '）'
            } else {
                return '100%（' + item.client_name + '）'
            } 
        },
        color_weight(item) {
            return item.client_id === null ? '#FF0000' : '#FF79BC';
        },
        color_tray_num(item) {
            return item.client_id === null ? '#B15BFF' : '#DCB5FF';
        }
    },
    filters:{},
    props:{
        PlanVisible: Boolean,
        data: Array
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        data(newVal) {
            this.dataA = [],
            this.dataB = [],
            this.dataC = [],
            this.dataD = [],
            this.dataE = [],
            newVal.forEach(v => {
                if(v.region === 'A') {
                    this.dataA.push(v)
                } else if(v.region === 'B') {
                    this.dataB.push(v)
                } else if(v.region === 'C') {
                    this.dataC.push(v)
                } else if(v.region === 'D') {
                    this.dataD.push(v)
                } else if(v.region === 'E') {
                    this.dataE.push(v)
                }
            })
            this.dataA.reverse()
            this.dataB.reverse()
            this.dataC.reverse()
            this.dataD.reverse()
            this.dataE.reverse()
            if((this.dataA.length === 0) && (this.dataC.length === 0)) {
                this.dialogFormTitle = '该仓库暂无库位图'
            } else {
                let storeroom_id
                if(this.dataA.length) {
                    this.dialogFormTitle = this.dataA[0].enterprise_name + '/' + this.dataA[0].storeroom_name
                    storeroom_id = this.dataA[0].storeroom_id
                } else if(this.dataC.length) {
                    this.dialogFormTitle = this.dataC[0].enterprise_name + '/' + this.dataC[0].storeroom_name
                    storeroom_id = this.dataC[0].storeroom_id
                }
                
                //获取仓库库区
                storeroom_list_request({storeroom_id:storeroom_id})
                    .then((s) => {
                        if (s.status === 0) {
                            this.regions = s.result[0].region
                            if((this.regions === 'AB') || (this.regions === 'CD')) {
                                this.dialog_width = '60%'
                            } else if((this.regions === 'ABCD') || (this.regions === 'ABCE')) {
                                this.dialog_width = '90%'
                            }
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
            }
        },
    }
}
</script>

<style lang='less'>
.rowc {
    border: black 1px solid;
}
.plandivc {
    border: black 1px solid;
    text-align: center;
    height: 20px;
    line-height: 20px;
    padding: 2px;
}
.plandivca {
    border: black 1px solid;
    text-align: center;
    height: 50px;
    line-height: 50px;
}
.plandivcb {
    text-align: center;
}
.el-progress-bar__outer {
    background-color: 	hsl(120, 100%, 61%);
}
.el-progress-bar__inner {
    text-align: left;
}
</style>
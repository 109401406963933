<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="库位编号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="库位编号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="所属库区" prop="region">
                <el-select
                    v-model="form.region"
                    placeholder="选择库区"
                    class="regionc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="r in ABCDE"
                        :key="r.name"
                        :label="r.name"
                        :value="r.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属企业" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_storeroom(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in fAllEnterprise"
                        :key="enterprise.id"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属仓库" prop="storeroom_id">
                <el-select
                    v-model="form.storeroom_id"
                    placeholder="选择仓库"
                    class="storeroomc"
                    @change="(storeroom_id) => get_location(storeroom_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="storeroom in storeroomInEnterprise"
                        :key="storeroom.id"
                        :label="storeroom.name"
                        :value="storeroom.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="租用类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        label="仓储"
                        :value=0
                    ></el-option>
                    <el-option
                        label="仓租"
                        :value=1
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="租用客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    placeholder="选择客户"
                    class="clientc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="client in fAllClient"
                        :key="client.id"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button type="success" plain @click="plan">库位平面图</el-button>
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加库位</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { storeroom_list_request } from '@/network/list.js'

export default {
    name: 'LocationSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                region: '',
                enterprise_id: '',
                storeroom_id: '',
                type: '',
                client_id: '',
                page: 1,
            },
            ABCDE: [
                { id: 'A', name: 'A'},
                { id: 'B', name: 'B'},
                { id: 'C', name: 'C'},
                { id: 'D', name: 'D'},
                { id: 'E', name: 'E'},
            ],
            storeroomInEnterprise: [],
        }
    },
    computed: {},
    methods: {
        plan() {
            if(this.form.storeroom_id) {
                this.$emit('ShowPlan', this.form.storeroom_id)
            } else {
                this.$message.warning('请先选择仓库')
            }
        },
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_storeroom(enterprise_id) {
            this.form.storeroom_id = ''
            this.storeroomInEnterprise = []
            if (enterprise_id) {
                storeroom_list_request({ enterprise_id })
                    .then((s) => {
                        this.loading = false
                        if (s.status === 0) {
                            this.storeroomInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err)
                    })
            }
        },
        get_location(storeroom_id) {
            this.$emit('getLocation',storeroom_id)
        }
    },
    filters: {},
    props: {
        fAllClient:Array,
        fAllEnterprise: Array,
        add_auth: String,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>
import request from '../request'

//库位列表
export const location_index_request = p => {
    return request({
        method:'GET',
        url:'location/index',
        params: p
    })
}

//添加库位
export const location_add_request = d => {
    return request({
        method:'POST',
        url:'location/add',
        data: {
            name: d.name,
            region: d.region,
            area: d.area,
            max_weight: d.max_weight,
            max_tray_num: d.max_tray_num,
            storeroom_id: d.storeroom_id,
            client_id: d.client_id,
            type: d.type
        }
    })
}

//删除库位
export const location_del_request = id => {
    return request({
        method:'DELETE',
        url:'location/del',
        data: {
            id: id
        }
    })
}

//修改库位
export const location_edit_request = d => {
    return request({
        method:'PUT',
        url:'location/edit',
        data: {
            id: d.id,
            name: d.name,
            region: d.region,
            area: d.area,
            max_weight: d.max_weight,
            max_tray_num: d.max_tray_num,
            storeroom_id: d.storeroom_id,
            client_id: d.client_id,
            type: d.type
        }
    })
}

//获取库位详情
export const location_details_request = id => {
    return request({
        method:'GET',
        url:'location/details',
        params: {
            id
        }
    })
}
<template>
    <div v-loading.fullscreen.lock="loading">
        <location-search-bar-component
            add_auth="新增库位"
            :fAllEnterprise="fAllEnterprise"
            :fAllClient="fAllClient"
            @search="get_location_index"
            @addTeam="addTeam"
            @getLocation="get_plan_location"
            @ShowPlan="show_plan"
        ></location-search-bar-component>
        <common-table-component
            details_auth="库位详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_location"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_location_index"
        ></common-page-component>
        <location-edit-component
            edit_auth="修改库位"
            del_auth="删除库位"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :fAllClient="fAllClient"
            :location_details_data="flocation_details_data"
            @exitLocationDialog="dialogExit"
            @search="get_location_index"
            @show_edit="show_edit"
            @details_row="details_location"
        ></location-edit-component>
        <location-plan-component
            :PlanVisible="fPlanVisible"
            :data="plan_data"
            @exitLocationPlanDialog="PlanDialogExit"
        ></location-plan-component>
    </div>
</template>

<script>
import { location_index_request,location_details_request } from '@/network/WareHouse/location.js'
import { client_list_request,enterprise_list_request} from '@/network/list.js'
import { fomatFloat,thousandBitSeparator} from '@/assets/js/common.js'

import LocationSearchBarComponent from '@/components/WareHouse/Location/LocationSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import LocationEditComponent from '@/components/WareHouse/Location/LocationEditComponent'
import LocationPlanComponent from '@/components/WareHouse/Location/LocationPlanComponent'

export default {
    name: 'LocationView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            fPlanVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                region: '',
                storeroom_id: '',
                enterprise_id: '',
                type: '',
                client_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '库位编号',
                    minWidth: '100px'
                },{
                    prop: 'area',
                    label: '面积(㎡)',
                    minWidth: '100px'
                },{
                    prop: 'max_weight',
                    label: '容量(吨)',
                    minWidth: '150px',
                    html: (row) => {
                        return `<span>` + fomatFloat(row['max_weight']) + `</span>/`
                            +  `<span style="color:red">` + fomatFloat(row['used_weight']/1000) + `</span>/`
                            +  `<span style="color:#00DB00">` + fomatFloat((row['max_weight']*1000 - row['used_weight'])/1000) + `</span>`
                    }
                },{
                    prop: 'max_tray_num',
                    label: '托盘数(个)',
                    minWidth: '110px',
                    html: (row) => {
                        return `<span>` + row['max_tray_num'] + `</span>/`
                            +  `<span style="color:red">` + row['used_tray_num'] + `</span>/`
                            +  `<span style="color:#00DB00">` + (row['max_tray_num'] - row['used_tray_num']) + `</span>`
                    }
                },{
                    prop: 'region',
                    label: '所属库区',
                    minWidth: '90px'
                },{
                    prop: 'storeroom_name',
                    label: '所属仓库',
                    minWidth: '130px'
                },{
                    prop: 'enterprise_name',
                    label: '所属企业',
                    minWidth: '260px'
                }
            ],
            flocation_details_data: {},
            plan_data: [],
            fAllClient: [],
            fAllEnterprise: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_location_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.region = param.region ?? this.cond.region
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.storeroom_id = param.storeroom_id ?? this.cond.storeroom_id
            this.cond.type = param.type ?? this.cond.type
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            location_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_location(id) {
            this.fid = id
            this.fdialogFormVisible = true
            location_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.flocation_details_data = s.result
                        this.flocation_details_data.storeroom_id = [
                            s.result.enterprise_id,
                            s.result.storeroom_id,
                        ]
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.flocation_details_data = {}
            this.fid = 0
            this.edit_type = 0
        },
        get_plan_location(storeroom_id) {
            location_index_request({ storeroom_id:storeroom_id, limit:500})
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.plan_data = s.result.data
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        show_plan() {
            this.fPlanVisible = true
        },
        PlanDialogExit() {
            this.fPlanVisible = false
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_location_index()
        this.get_all_client()
        this.get_all_enterprise()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        LocationSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        LocationEditComponent,
        LocationPlanComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>